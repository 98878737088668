"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateProductData = void 0;
const prepareProductData_1 = require("./prepareProductData");
const updateProductData = () => {
    if (!window.blaze.productData) {
        prepareProductData_1.prepareProductData();
        return;
    }
    const { quantity, variantId, price, variantTitle } = prepareProductData_1.getFinalFormData(window.originalProductData);
    window.blaze.productData.items[0].quantity = quantity;
    window.blaze.productData.items[0].variantId = variantId;
    window.blaze.productData.items[0].variantTitle = variantTitle;
    window.blaze.productData.items[0].price = price;
};
exports.updateProductData = updateProductData;
