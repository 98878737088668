"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackCriteoStep = void 0;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trackCriteoStep = (data) => {
    const { eventName, platformOrderId } = data;
    if (eventName === 'checkout.payment.success') {
        window.criteo_q = window.criteo_q || [];
        const deviceType = getDeviceType();
        window.criteo_q.push({ event: 'setAccount', account: 93995 }, { event: 'setEmail', email: '##Email Address##', hash_method: '##Hash Method##' }, { event: 'setSiteType', type: deviceType }, { event: 'setZipcode', zipcode: '##Zip Code##' }, {
            event: 'trackTransaction',
            id: platformOrderId,
            item: getItems(),
        });
    }
};
exports.trackCriteoStep = trackCriteoStep;
const getItems = () => {
    var _a;
    const items = (_a = window.blaze.currentCartData) === null || _a === void 0 ? void 0 : _a.items.map((item) => {
        const { productId, price, quantity } = item;
        return {
            id: productId,
            price: price / 100,
            quantity,
        };
    });
    return items || [];
};
const getDeviceType = () => {
    if (/iPad/.test(navigator.userAgent)) {
        return 't';
    }
    if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent)) {
        return 'm';
    }
    return 'd';
};
