"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getWindowUrl = void 0;
const js_base64_1 = require("js-base64");
const getWindowUrl = ({ url, colorMode, store, orderType, data, }) => {
    const stringifiedCartItems = JSON.stringify(data === null || data === void 0 ? void 0 : data.items);
    const encodedItems = js_base64_1.encode(stringifiedCartItems, true);
    // encode and send current href as redirectUrl
    const encodedUrl = js_base64_1.encode(window.location.href, true);
    return `${url}&store=${store}&orderType=${orderType}&redirectUrl=${encodedUrl}${colorMode ? `&colorMode=${colorMode}` : ''}&cartItems=${encodedItems}`;
};
exports.getWindowUrl = getWindowUrl;
