"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessageHandler = void 0;
const blaze_1 = require("./blaze");
const browserUtil_1 = require("./browserUtil");
const overlayUtils_1 = require("./overlayUtils");
const tracking_1 = require("./tracking/tracking");
const iframeUtils_1 = require("./utils/iframeUtils");
const goToURL = (href) => {
    if (href) {
        window.location.href = href;
    }
};
const closeIframe = () => {
    overlayUtils_1.hideOverlay();
    iframeUtils_1.removeIframe();
    const checkoutId = blaze_1.getUrlSearchParam('checkoutId');
    if (checkoutId) {
        browserUtil_1.removeQueryParam('checkoutId');
    }
};
const clearCart = () => {
    var _a;
    // clear shopify cart
    if (((_a = window.blaze.uiSettings) === null || _a === void 0 ? void 0 : _a.storePlatform) === 'WOOCOMMERCE') {
        const cartClearUrl = `https://${window.location.host}/?cart_clear=true`;
        fetch(cartClearUrl, {
            method: 'GET',
        });
        return;
    }
    const cartClearUrl = `https://${window.location.host}/cart/clear.json`;
    fetch(cartClearUrl, {
        method: 'POST',
    });
};
/**
 * Handle a message that was sent from some window.
 *
 * @param {MessageEvent} event The MessageEvent object holding the message/data.
 */
const getMessageHandler = () => {
    return (event) => {
        // When one window sends a message, or data, to another window via
        // `parent.postMessage()`, the message (the first argument) in the
        // `parent.postMessage()` call is accessible via `event.data` here.
        const messageFromSender = event.data;
        // Do something with the data.
        if (messageFromSender.type === 'blaze-checkout-step') {
            tracking_1.trackStep(messageFromSender);
        }
        if (messageFromSender.type === 'blaze-checkout-success-redirect') {
            goToURL(messageFromSender.link);
        }
        if (messageFromSender.type === 'blaze-checkout-upi') {
            goToURL(messageFromSender.deepLink);
            return;
        }
        if (messageFromSender.type === 'success') {
            closeIframe();
            goToURL('/');
            return;
        }
        if (messageFromSender.type === 'blaze-checkout-close') {
            closeIframe();
            // close the iframe and go back to same page
        }
        if (messageFromSender.type === 'clear_cart') {
            clearCart();
        }
    };
};
exports.getMessageHandler = getMessageHandler;
