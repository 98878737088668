"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchUiSettings = void 0;
const API_URL = process.env.API_BASE_URL;
const fetchUiSettings = ({ shop }) => {
    return fetch(`${API_URL}/store/uisettings?store=${shop}`).then(response => {
        return response.json().then((values) => {
            const { uiConfig } = values;
            if (uiConfig) {
                try {
                    const parsedConfig = JSON.parse(uiConfig);
                    return Object.assign(Object.assign({}, values), { uiConfig: parsedConfig });
                }
                catch (_a) {
                    return Object.assign(Object.assign({}, values), { uiConfig: undefined });
                }
            }
            return Object.assign(Object.assign({}, values), { uiConfig: undefined });
        });
    });
};
exports.fetchUiSettings = fetchUiSettings;
