"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultSelectors = void 0;
const blaze_1 = require("./blaze");
const getDefaultSelectors = (platform) => {
    if (platform === 'WOOCOMMERCE') {
        return {
            defaultProductButtonSiblingSelector: blaze_1.DEFAULT_WOOCOMMERCE_PDP_SELECTOR,
            defaultCartButtonSiblingSelector: blaze_1.DEFAULT_WOOCOMMERCE_CART_SELECTOR,
        };
    }
    return {
        defaultProductButtonSiblingSelector: blaze_1.DEFAULT_PRODUCT_BUTTON_SIBLING_SELECTOR,
        defaultCartButtonSiblingSelector: blaze_1.DEFAULT_CART_BUTTON_SIBLING_SELECTOR,
    };
};
exports.getDefaultSelectors = getDefaultSelectors;
