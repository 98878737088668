"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hideOverlay = exports.showOverlay = exports.appendContainer = void 0;
const browserUtil_1 = require("./browserUtil");
const stopBodyScroll_1 = require("./stopBodyScroll");
const iframeUtils_1 = require("./utils/iframeUtils");
const appendContainer = () => {
    const container = document.createElement('div');
    container.classList.add('blaze-checkout-container');
    document.body.appendChild(container);
    return container;
};
exports.appendContainer = appendContainer;
const showOverlay = ({ checkoutWindow, container, }) => {
    iframeUtils_1.showIframe();
    const isMobileDevice = browserUtil_1.isMobile();
    // create blaze overlay div
    const overlay = document.createElement('div');
    overlay.className = 'blaze-overlay';
    // add text span to overlay
    const text = document.createElement('span');
    text.className = 'blaze-overlay-text';
    text.innerText = 'No longer see the Blaze window?';
    overlay.appendChild(text);
    // add 'click here' button to overlay
    const button = document.createElement('button');
    button.className = 'blaze-overlay-button-click-here';
    if (isMobileDevice) {
        button.innerText = 'Continue shopping';
        // remove overlay on click
        button.addEventListener('click', () => {
            exports.hideOverlay();
            stopBodyScroll_1.startBodyScroll();
            checkoutWindow === null || checkoutWindow === void 0 ? void 0 : checkoutWindow.close();
        });
    }
    else {
        button.innerText = 'Click here';
        // call .focus() on button click
        button.addEventListener('click', focusWindow(checkoutWindow));
    }
    overlay.appendChild(button);
    container.appendChild(overlay);
};
exports.showOverlay = showOverlay;
const focusWindow = (newWindow) => {
    // focus on new window
    return () => {
        newWindow === null || newWindow === void 0 ? void 0 : newWindow.focus();
    };
};
const hideOverlay = () => {
    // remove blaze overlay div
    const container = document.querySelector('.blaze-checkout-container');
    const overlay = document.querySelector('.blaze-overlay');
    if (overlay) {
        container === null || container === void 0 ? void 0 : container.removeChild(overlay);
    }
    container && document.body.removeChild(container);
};
exports.hideOverlay = hideOverlay;
