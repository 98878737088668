"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupCartPage = void 0;
const getBlazeCheckoutButton_1 = require("./getBlazeCheckoutButton");
const prepareCartData_1 = require("./cart/prepareCartData");
const iframeUtils_1 = require("./utils/iframeUtils");
const appendButton = (cartButtonSiblingSelector, buttonWrapper) => {
    var _a, _b, _c, _d, _e;
    const checkoutButton = document.querySelector(cartButtonSiblingSelector);
    const buttonPosition = (_d = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.blaze) === null || _a === void 0 ? void 0 : _a.uiSettings) === null || _b === void 0 ? void 0 : _b.uiConfig) === null || _c === void 0 ? void 0 : _c.cartButton) === null || _d === void 0 ? void 0 : _d.position;
    if (buttonPosition === 'after') {
        checkoutButton === null || checkoutButton === void 0 ? void 0 : checkoutButton.after(buttonWrapper);
    }
    else {
        (_e = checkoutButton === null || checkoutButton === void 0 ? void 0 : checkoutButton.parentNode) === null || _e === void 0 ? void 0 : _e.insertBefore(buttonWrapper, checkoutButton);
    }
};
const setupCartPage = (options) => {
    const { cartButtonText, iframeUrl, cartButtonSiblingSelector, extraDiscount, buttonColor, settings } = options;
    const { buttonWrapper, blazeCheckoutButton } = getBlazeCheckoutButton_1.getBlazeCheckoutButton({
        buttonText: cartButtonText,
        buttonType: 'cart',
        extraDiscount,
        enabled: true,
        buttonColor,
        isLoading: window.blaze.isInitialCartLoading,
    });
    appendButton(cartButtonSiblingSelector, buttonWrapper);
    blazeCheckoutButton.addEventListener('click', () => {
        !window.blaze.isCartLoading && prepareCartData_1.prepareCartData();
        const initializationOptions = {
            settings,
            iframeUrl,
            type: 'cart',
        };
        iframeUtils_1.initiaizeIframe(initializationOptions);
    });
};
exports.setupCartPage = setupCartPage;
