"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupRecoveryPage = void 0;
const iframeUtils_1 = require("./utils/iframeUtils");
const setupRecoveryPage = (options) => {
    const { iframeUrl, settings, checkoutId } = options;
    const { allowAbandonedCheckout, blaze_enabled, admin_enabled } = window.blaze.uiSettings || {};
    if (!allowAbandonedCheckout || !blaze_enabled || !admin_enabled || !checkoutId) {
        return;
    }
    const initializationOptions = {
        settings,
        iframeUrl,
        type: 'recovery',
    };
    iframeUtils_1.initiaizeIframe(initializationOptions);
};
exports.setupRecoveryPage = setupRecoveryPage;
