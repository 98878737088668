"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackStep = void 0;
const trackCriteo_1 = require("./trackCriteo");
const trackGA_1 = require("./trackGA");
const getWebengageProducts = () => {
    var _a;
    let cartItemsTotal = 0;
    let productIdList = [];
    const webengageProducts = (_a = window.blaze.currentCartData) === null || _a === void 0 ? void 0 : _a.items.map((item) => {
        const { productId, variantId, title, price, quantity, image } = item;
        cartItemsTotal += item.price;
        productIdList = [...productIdList, productId];
        return {
            'Product ID': productId,
            'Product Name': title,
            'Product Price': price / 100,
            'Product Quantity': quantity,
            'Product Variant Id': variantId,
            Currency: 'INR',
            'Image Url': image,
            // 'Product Url': productUrl, // todo: add this by using client cart data and fetching cart from backend?
        };
    });
    return { webengageProducts, cartItemsTotal, productIdList: productIdList.join(',') };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getWebengageAddressData = (data) => {
    const { address, formattedAddress, phone } = data;
    const { email, fullName, city, state, pincode, addressType } = address;
    const [firstName, lastName] = fullName.split(' ');
    return {
        firstName,
        lastName,
        email,
        webengageAddress: {
            Phone: phone || '',
            'First Name': firstName,
            'Last Name': lastName,
            Email: email,
            'Full Address': formattedAddress,
            City: city,
            State: state,
            Pincode: pincode,
            'Address Type': addressType,
        },
    };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trackWebengageStep = (data) => {
    if (!window.webengage) {
        return;
    }
    if (data.eventName === 'checkout.load') {
        const { webengageProducts, cartItemsTotal, productIdList } = getWebengageProducts();
        window.webengage.track('Checkout_Started_BLZ', {
            Tag: 'Blaze',
            Products: webengageProducts,
            'Product ID': productIdList,
            'No. Of Products': webengageProducts === null || webengageProducts === void 0 ? void 0 : webengageProducts.length,
            Currency: 'INR',
            'Cart Total Price': cartItemsTotal / 100,
        });
        return;
    }
    if (data.eventName === 'checkout.load.user') {
        const { phone } = data;
        phone && window.webengage.user.setAttribute('we_phone', phone);
        window.webengage.track('Phone_Validated_BLZ', {
            Phone: phone,
            Tag: 'Blaze',
        });
        return;
    }
    if (data.eventName === 'checkout.address.updated') {
        const { firstName, lastName, email, webengageAddress } = getWebengageAddressData(data);
        if (email) {
            window.webengage.user.setAttribute('we_email', email);
        }
        firstName && window.webengage.user.setAttribute('we_first_name', firstName);
        lastName && window.webengage.user.setAttribute('we_last_name', lastName);
        const { webengageProducts, cartItemsTotal, productIdList } = getWebengageProducts();
        window.webengage.track('Address_Added_BLZ', Object.assign(Object.assign({ Products: webengageProducts, 'No. Of Products': webengageProducts === null || webengageProducts === void 0 ? void 0 : webengageProducts.length, 'Product ID': productIdList }, webengageAddress), { 'Cart Total Price': cartItemsTotal / 100, Tag: 'Blaze' }));
        return;
    }
    if (data.eventName === 'checkout.payment.upiOrder.created') {
        const { platformCustomerId } = data;
        platformCustomerId && window.webengage.user.login(platformCustomerId);
    }
    if (data.eventName === 'checkout.payment.success') {
        const { discountCode, paymentMethod } = data;
        const { webengageProducts, cartItemsTotal, productIdList } = getWebengageProducts();
        const { webengageAddress } = getWebengageAddressData(data);
        window.webengage.track('Payment_Complete_BLZ', Object.assign(Object.assign({ Products: webengageProducts }, webengageAddress), { 'No. Of Products': webengageProducts === null || webengageProducts === void 0 ? void 0 : webengageProducts.length, 'Product ID': productIdList, 'Coupon Code': discountCode || '', 'Payment Method': paymentMethod, 'Cart Total Price': cartItemsTotal / 100, Tag: 'Blaze' }));
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trackFbStep = (data) => {
    if (!window.fbq) {
        return;
    }
    if (data.eventName === 'checkout.load') {
        const content_ids = data.cartItems.map((item) => item.productId);
        const contents = data.cartItems.map((item) => {
            return {
                id: item.productId,
                variant: item.variantId,
                name: item.title,
                price: item.price / 100,
                currency: 'INR',
                quantity: item.quantity,
            };
        });
        const num_items = data.cartItems.reduce((acc, item) => acc + item.quantity, 0);
        window.fbq('track', 'InitiateCheckout', {
            content_type: 'product_group',
            value: data.cartItemsTotal / 100,
            content_ids,
            contents,
            num_items,
            currency: 'INR',
        }, {
            eventID: 'Blaze '.concat(`${Math.random()}`),
        });
        return;
    }
    if (data.eventName === 'checkout.payment.success') {
        const content_ids = data.cartItems.map((item) => item.productId);
        const contents = data.cartItems.map((item) => {
            return {
                id: item.productId,
                variant: item.variantId,
                name: item.title,
                price: item.price / 100,
                currency: 'INR',
                quantity: item.quantity,
            };
        });
        const num_items = data.cartItems.reduce((acc, item) => acc + item.quantity, 0);
        window.fbq('track', 'Purchase', {
            value: data.cartItemsTotal / 100,
            content_ids,
            contents,
            num_items,
            currency: 'INR',
            content_type: 'product_group',
        }, {
            eventID: 'Blaze '.concat(`${Math.random()}`),
        });
    }
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trackStep = (data) => {
    trackWebengageStep(data);
    trackFbStep(data);
    trackGA_1.trackGAStep(data);
    trackCriteo_1.trackCriteoStep(data);
};
exports.trackStep = trackStep;
