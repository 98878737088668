"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupCartChangeListener = void 0;
const getBlazeCheckoutButton_1 = require("../getBlazeCheckoutButton");
const prepareCartData_1 = require("./prepareCartData");
const CART_CHANGE_URL = 'cart/change';
const stopProgressTimer = (time = 5000) => {
    // stop progress if not done in 5s
    setTimeout(() => {
        getBlazeCheckoutButton_1.setCartButtonInProgress(false);
    }, time);
};
const addXmlListener = () => {
    const oldOpen = XMLHttpRequest.prototype.open;
    // eslint-disable-next-line func-names
    XMLHttpRequest.prototype.open = function (method, url, async, username, password) {
        let newUrl = url;
        if (typeof newUrl !== 'string') {
            newUrl = newUrl.toString();
        }
        if (newUrl.includes(CART_CHANGE_URL)) {
            getBlazeCheckoutButton_1.setCartButtonInProgress(true);
            stopProgressTimer();
        }
        // eslint-disable-next-line func-names
        this.addEventListener('load', function () {
            if (newUrl.includes(CART_CHANGE_URL)) {
                prepareCartData_1.prepareCartData().then(() => {
                    getBlazeCheckoutButton_1.setCartButtonInProgress(false);
                });
            }
        });
        return oldOpen.call(this, method, url, async, username, password);
    };
};
const addFetchListener = () => {
    const nativeFetch = window.fetch;
    // monkey patch fetch to detect start of request
    window.fetch = (...args) => {
        const [requestInfo] = args;
        let requestUrl = requestInfo;
        if (typeof requestInfo !== 'string') {
            requestUrl = requestInfo.url;
        }
        if (!requestUrl) {
            return nativeFetch.apply(window, args);
        }
        if (requestUrl.includes(CART_CHANGE_URL)) {
            stopProgressTimer();
            getBlazeCheckoutButton_1.setCartButtonInProgress(true);
        }
        return nativeFetch.apply(window, args);
    };
    // this tells us when request ends
    const fetchEndObserver = new PerformanceObserver(list => {
        list.getEntries().forEach(entry => {
            if (entry.initiatorType === 'fetch') {
                if (entry.name.includes(CART_CHANGE_URL)) {
                    prepareCartData_1.prepareCartData().then(() => {
                        getBlazeCheckoutButton_1.setCartButtonInProgress(false);
                    });
                }
            }
        });
    });
    fetchEndObserver.observe({
        entryTypes: ['resource'],
    });
};
const addFormMutationListener = () => {
    var _a;
    if (((_a = window.blaze.uiSettings) === null || _a === void 0 ? void 0 : _a.storePlatform) === 'WOOCOMMERCE') {
        const mutationObserver = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'attributes') {
                    if (mutation.target.nodeName === 'FORM') {
                        prepareCartData_1.prepareCartData({ loadAsync: true }).then(() => {
                            getBlazeCheckoutButton_1.setCartButtonInProgress(false);
                        });
                    }
                }
            });
        });
        mutationObserver.observe(document, {
            childList: true,
            subtree: true,
            attributes: true,
        });
    }
};
const setupCartChangeListener = () => {
    addXmlListener();
    addFetchListener();
    addFormMutationListener();
};
exports.setupCartChangeListener = setupCartChangeListener;
