"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupProductPage = void 0;
const getBlazeCheckoutButton_1 = require("./getBlazeCheckoutButton");
const getProductFormElement_1 = require("./product/getProductFormElement");
const updateProductData_1 = require("./product/updateProductData");
const iframeUtils_1 = require("./utils/iframeUtils");
const appendButton = (productButtonSiblingSelector, buttonWrapper) => {
    var _a, _b, _c, _d;
    const addToCartButton = document.querySelector(productButtonSiblingSelector);
    const buttonPosition = (_d = (_c = (_b = (_a = window === null || window === void 0 ? void 0 : window.blaze) === null || _a === void 0 ? void 0 : _a.uiSettings) === null || _b === void 0 ? void 0 : _b.uiConfig) === null || _c === void 0 ? void 0 : _c.productButton) === null || _d === void 0 ? void 0 : _d.position;
    if (buttonPosition === 'after') {
        addToCartButton === null || addToCartButton === void 0 ? void 0 : addToCartButton.after(buttonWrapper);
    }
    else {
        const addToCartButtonParent = addToCartButton === null || addToCartButton === void 0 ? void 0 : addToCartButton.parentNode;
        addToCartButtonParent === null || addToCartButtonParent === void 0 ? void 0 : addToCartButtonParent.insertBefore(buttonWrapper, addToCartButton);
    }
    getBlazeCheckoutButton_1.checkButtonValidity();
};
const addButtonValidityListener = () => {
    const formElement = getProductFormElement_1.getProductFormElement();
    formElement === null || formElement === void 0 ? void 0 : formElement.addEventListener('change', () => {
        updateProductData_1.updateProductData();
        getBlazeCheckoutButton_1.checkButtonValidity();
    });
};
const setupProductPage = (options) => {
    const { productButtonText, iframeUrl, productButtonSiblingSelector, extraDiscount, buttonColor, settings } = options;
    const { buttonWrapper, blazeCheckoutButton } = getBlazeCheckoutButton_1.getBlazeCheckoutButton({
        buttonText: productButtonText,
        buttonType: 'product',
        extraDiscount,
        enabled: true,
        buttonColor,
        isLoading: window.blaze.isInitialProductLoading,
    });
    appendButton(productButtonSiblingSelector, buttonWrapper);
    addButtonValidityListener();
    blazeCheckoutButton.addEventListener('click', () => {
        updateProductData_1.updateProductData();
        if (!getBlazeCheckoutButton_1.checkButtonValidity()) {
            return;
        }
        const initializationOptions = {
            settings,
            iframeUrl,
            type: 'product',
        };
        iframeUtils_1.initiaizeIframe(initializationOptions);
    });
};
exports.setupProductPage = setupProductPage;
