"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initiaizeIframe = exports.removeIframe = exports.showIframe = exports.appendIframe = void 0;
const prepareCartData_1 = require("../cart/prepareCartData");
const getWindowUrl_1 = require("../getWindowUrl");
const overlayUtils_1 = require("../overlayUtils");
const prepareProductData_1 = require("../product/prepareProductData");
const stopBodyScroll_1 = require("../stopBodyScroll");
const appendIframe = (container) => {
    const exisitingIframe = document.querySelector('#blaze-iframe');
    if (exisitingIframe) {
        return exisitingIframe;
    }
    const iframe = document.createElement('iframe');
    iframe.name = 'blaze-checkout-frame-container';
    iframe.id = 'blaze-iframe';
    iframe.title = 'blazeIframe';
    iframe.setAttribute('allow', 'accelerometer');
    const iframeContainer = document.createElement('div');
    iframeContainer.id = 'blaze-iframe-container';
    iframeContainer.appendChild(iframe);
    const loader = document.createElement('div');
    loader.classList.add('blaze-loader');
    iframeContainer.appendChild(loader);
    container.appendChild(iframeContainer);
    return iframe;
};
exports.appendIframe = appendIframe;
const showIframe = () => {
    const iframeContainer = document.getElementById('blaze-iframe-container');
    iframeContainer === null || iframeContainer === void 0 ? void 0 : iframeContainer.classList.add('blaze-iframe-visible');
};
exports.showIframe = showIframe;
const removeIframe = () => {
    const iframeContainer = document.getElementById('blaze-iframe-container');
    const container = document.querySelector('.blaze-checkout-container');
    iframeContainer && container && container.removeChild(iframeContainer);
};
exports.removeIframe = removeIframe;
const initiaizeIframe = (options) => {
    const { settings, iframeUrl, type } = options;
    const container = overlayUtils_1.appendContainer();
    const iframe = exports.appendIframe(container);
    const height = 720;
    const width = 480;
    const top = (window.outerHeight - height) / 2 + window.screenTop;
    const left = (window.outerWidth - width) / 2 + window.screenLeft;
    const { colorMode, store } = settings;
    window.blaze.checkoutType = type;
    const dataForUrl = type === 'product' ? window.blaze.productData : window.blaze.cartData;
    const iframeUrlWithData = getWindowUrl_1.getWindowUrl({
        url: iframeUrl,
        orderType: type,
        data: dataForUrl,
        store,
        colorMode,
    });
    if (type === 'product') {
        window.blaze.currentCartData = window.blaze.productData;
    }
    else if (type === 'cart') {
        window.blaze.currentCartData = prepareCartData_1.parseClientCart(window.blaze.cartData);
    }
    const checkoutWindow = window.open(iframeUrlWithData, 'blaze-checkout-frame-container', `height=${height},width=${width},top=${top}, left=${left}`);
    overlayUtils_1.showOverlay({ checkoutWindow, container });
    stopBodyScroll_1.stopBodyScroll();
    iframe.onload = () => {
        if (type === 'recovery') {
            return;
        }
        if (type === 'cart') {
            prepareCartData_1.prepareCartData({ loadAsync: true }).then(data => {
                var _a, _b;
                window.blaze.currentCartData = prepareCartData_1.parseClientCart(data || window.blaze.cartData);
                (_a = iframe === null || iframe === void 0 ? void 0 : iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({
                    type: 'blaze-checkout-cart-data',
                    cart: (_b = (data || window.blaze.cartData)) === null || _b === void 0 ? void 0 : _b.items,
                }, iframeUrlWithData);
            });
            return;
        }
        prepareProductData_1.prepareProductData().then(data => {
            var _a, _b;
            window.blaze.currentCartData = data || window.blaze.productData;
            (_a = iframe === null || iframe === void 0 ? void 0 : iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.postMessage({
                type: 'blaze-checkout-cart-data',
                cart: (_b = (data || window.blaze.productData)) === null || _b === void 0 ? void 0 : _b.items,
            }, iframeUrlWithData);
        });
    };
    if (checkoutWindow) {
        const timer = setInterval(() => {
            if (checkoutWindow.closed) {
                overlayUtils_1.hideOverlay();
                stopBodyScroll_1.startBodyScroll();
                clearInterval(timer);
            }
        }, 200);
    }
};
exports.initiaizeIframe = initiaizeIframe;
