"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseClientCart = exports.prepareCartData = void 0;
const getBlazeCheckoutButton_1 = require("../getBlazeCheckoutButton");
const SHOPIFY_CART_DATA_PATH = `${window.location.origin}/cart.json`;
const WOOCOMMERCE_CART_DATA_PATH = `${window.location.origin}/?is_cart_json=true`;
const prepareCartData = (options) => {
    if (options === null || options === void 0 ? void 0 : options.initial) {
        getBlazeCheckoutButton_1.setCartButtonInProgress(true);
        window.blaze.isInitialCartLoading = true;
    }
    window.blaze.isCartLoading = true;
    return fetchCartData(options === null || options === void 0 ? void 0 : options.loadAsync);
};
exports.prepareCartData = prepareCartData;
const parseAndSetData = (data) => {
    window.blaze.platformCartData = data;
    const cartData = parseData(data);
    window.blaze.isCartLoading = false;
    window.blaze.cartData = cartData;
    window.blaze.isInitialCartLoading = false;
    getBlazeCheckoutButton_1.setCartButtonInProgress(false);
    return cartData;
};
const fetchCartData = (loadAsync) => {
    var _a;
    let cartDataPath = SHOPIFY_CART_DATA_PATH;
    if (window.woocommerceCartData) {
        const cartData = parseAndSetData(window.woocommerceCartData);
        return Promise.resolve(cartData);
    }
    if (((_a = window.blaze.uiSettings) === null || _a === void 0 ? void 0 : _a.storePlatform) === 'WOOCOMMERCE') {
        cartDataPath = WOOCOMMERCE_CART_DATA_PATH;
    }
    return fetch(cartDataPath)
        .then(response => {
        return response.text();
    })
        .then(res => {
        let data = {};
        data = JSON.parse(res);
        const cartData = parseAndSetData(data);
        return cartData;
    })
        .catch(error => {
        window.blaze.isCartLoading = false;
        window.blaze.isInitialCartLoading = false;
        getBlazeCheckoutButton_1.setCartButtonInProgress(false);
        // eslint-disable-next-line no-console
        console.log('error!', error);
    });
};
const parseData = (data) => {
    const cartData = {};
    cartData.items = [];
    if (!(data && data.items && data.items.length)) {
        return cartData;
    }
    data.items.forEach(item => {
        const cartItem = {
            variantId: item.id,
            productId: item.product_id,
            title: item.product_title,
            variantTitle: item.variant_title ? item.variant_title : undefined,
            price: item.price,
            quantity: item.quantity,
            image: item.image,
            properties: item.properties,
        };
        cartData.items.push(cartItem);
    });
    return cartData;
};
const parseClientCart = (cartData) => {
    return cartData;
};
exports.parseClientCart = parseClientCart;
