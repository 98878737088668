"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.observeCartForm = void 0;
const prepareCartData_1 = require("./prepareCartData");
const setupCartPage_1 = require("../setupCartPage");
const CART_FORM_SELECTOR = 'form[action="/cart"]';
const observeCartForm = (options) => {
    const blazeCartButton = document.querySelector('.blaze-cart-button');
    const isCartCheckoutPage = window.location.pathname.indexOf('/cart') !== -1;
    if (isCartCheckoutPage && !blazeCartButton) {
        // in case there's no cart form
        setupCartPage_1.setupCartPage(options);
    }
    addCartFormListener();
    !window.blaze.isCartLoading && prepareCartData_1.prepareCartData();
    const cartObserver = new MutationObserver(checkCartVisible(options));
    cartObserver.observe(document, { attributes: true, childList: true, characterData: false, subtree: true });
};
exports.observeCartForm = observeCartForm;
const checkCartVisible = (options) => (mutations, observer) => {
    mutations.forEach(mutation => {
        const isFormPresent = document.contains(getFormElement());
        const isElementPresent = document.contains(document.querySelector(options.cartButtonSiblingSelector));
        const addedNodes = [...mutation.addedNodes];
        const isFormAdded = addedNodes.find(node => { var _a, _b; return (_b = (_a = node).matches) === null || _b === void 0 ? void 0 : _b.call(_a, CART_FORM_SELECTOR); });
        const isElementAdded = addedNodes.find(node => { var _a, _b; return (_b = (_a = node).matches) === null || _b === void 0 ? void 0 : _b.call(_a, options.cartButtonSiblingSelector); });
        const foundForm = isFormPresent || isFormAdded || isElementAdded || isElementPresent;
        if (foundForm) {
            const blazeButton = document.querySelector('.blaze-cart-button');
            !blazeButton && setupCartPage_1.setupCartPage(options);
        }
        const isCartButtonAdded = addedNodes.find(node => { var _a, _b; return (_b = (_a = node).matches) === null || _b === void 0 ? void 0 : _b.call(_a, '.blaze-cart-button'); });
        if (isElementAdded || isFormAdded || isCartButtonAdded) {
            prepareCartData_1.prepareCartData();
        }
    });
};
const addCartFormListener = () => {
    const formElement = getFormElement();
    formElement &&
        formElement.addEventListener('click', event => {
            // on form change, update the cart data
            !window.blaze.isCartLoading && prepareCartData_1.prepareCartData();
        });
};
const getFormElement = () => {
    return document.querySelector(CART_FORM_SELECTOR);
};
