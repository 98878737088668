"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_WOOCOMMERCE_CART_SELECTOR = exports.DEFAULT_WOOCOMMERCE_PDP_SELECTOR = exports.DEFAULT_CART_BUTTON_SIBLING_SELECTOR = exports.DEFAULT_PRODUCT_BUTTON_SIBLING_SELECTOR = exports.getUrlSearchParam = void 0;
/* eslint-disable @typescript-eslint/explicit-function-return-type */
const fetchUiSettings_1 = require("./fetchUiSettings");
const getBlazeCheckoutButton_1 = require("./getBlazeCheckoutButton");
const getDefaultSelectors_1 = require("./getDefaultSelectors");
const getMessageHandler_1 = require("./getMessageHandler");
const setupCartChangeListener_1 = require("./cart/setupCartChangeListener");
const prepareCartData_1 = require("./cart/prepareCartData");
const observeCartForm_1 = require("./cart/observeCartForm");
const updateProductData_1 = require("./product/updateProductData");
const prepareProductData_1 = require("./product/prepareProductData");
const observeProductForm_1 = require("./product/observeProductForm");
const setupRecoveryPage_1 = require("./setupRecoveryPage");
const IFRAME_URL = process.env.IFRAME_BASE_URL;
const getUrlSearchParam = (query) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return urlSearchParams.get(query);
};
exports.getUrlSearchParam = getUrlSearchParam;
const getIframeUrl = (id) => {
    const checkoutId = exports.getUrlSearchParam('checkoutId');
    const url = `${IFRAME_URL}/?merchantId=${id}${checkoutId ? `&checkoutId=${checkoutId}` : ''}`;
    return url;
};
exports.DEFAULT_PRODUCT_BUTTON_SIBLING_SELECTOR = '#AddToCart--product';
exports.DEFAULT_CART_BUTTON_SIBLING_SELECTOR = '[name="checkout"]';
exports.DEFAULT_WOOCOMMERCE_PDP_SELECTOR = '#blaze-product-sibling';
exports.DEFAULT_WOOCOMMERCE_CART_SELECTOR = '#blaze-cart-sibling';
const setupCheckout = () => {
    var _a;
    if ((_a = window.blaze) === null || _a === void 0 ? void 0 : _a.scriptLoaded) {
        return;
    }
    const { shop } = window.Shopify || { shop: window.location.host };
    window.blaze = {
        isCartLoading: false,
        isInitialCartLoading: false,
        isInitialProductLoading: false,
        scriptLoaded: true,
        isNoProductSelected: false,
    };
    prepareCartData_1.prepareCartData({ initial: true });
    prepareProductData_1.prepareProductData({ initial: true });
    window.setTimeout(() => {
        // hack -- this is needed to enable product button
        // when a default variant is selected (woocommerce)
        updateProductData_1.updateProductData();
        getBlazeCheckoutButton_1.checkButtonValidity();
    }, 1000);
    fetchUiSettings_1.fetchUiSettings({ shop })
        .then((uiSettings) => {
        const { blaze_enabled, admin_enabled } = uiSettings;
        if (!blaze_enabled || !admin_enabled) {
            // don't load blaze button if disabled
            /*
              idea: to avoid this API call,
              maybe we can put it as a query param in the script itself,
              plus do a check on BE for all API calls.
              But what about button placement?
            */
            return;
        }
        window.blaze.uiSettings = uiSettings;
        injectStyleSheet();
        setupCartChangeListener_1.setupCartChangeListener();
        window.addEventListener('message', getMessageHandler_1.getMessageHandler());
        const { id, colorMode, productButtonText, cartButtonText, buttonSiblingSelector, cartButtonSiblingSelector, extraDiscount, buttonColor, buttonStyleSheet, } = uiSettings;
        const { defaultProductButtonSiblingSelector, defaultCartButtonSiblingSelector } = getDefaultSelectors_1.getDefaultSelectors(uiSettings.storePlatform);
        const productButtonSiblingSelector = buttonSiblingSelector || defaultProductButtonSiblingSelector;
        const finalCartButtonSiblingSelector = cartButtonSiblingSelector || defaultCartButtonSiblingSelector;
        const iframeUrl = getIframeUrl(id);
        const checkoutOptions = {
            iframeUrl,
            extraDiscount,
            buttonColor,
            buttonStyleSheet,
            settings: { colorMode, store: shop },
        };
        const checkoutId = exports.getUrlSearchParam('checkoutId');
        setupRecoveryPage_1.setupRecoveryPage({ iframeUrl, settings: { colorMode, store: shop }, checkoutId });
        observeCartForm_1.observeCartForm(Object.assign(Object.assign({}, checkoutOptions), { cartButtonText, cartButtonSiblingSelector: finalCartButtonSiblingSelector }));
        observeProductForm_1.observeProductForm(Object.assign(Object.assign({}, checkoutOptions), { productButtonText,
            productButtonSiblingSelector }));
    })
        .catch(err => {
        // eslint-disable-next-line no-console
        console.log(err);
    });
};
(() => {
    setupCheckout();
})();
const injectStyleSheet = () => {
    var _a;
    const styleTag = document.createElement('style');
    styleTag.id = 'blaze-button-styles';
    styleTag.textContent = ((_a = window.blaze.uiSettings) === null || _a === void 0 ? void 0 : _a.buttonStyleSheet) || '';
    document.body.append(styleTag);
};
