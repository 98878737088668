"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.startBodyScroll = exports.stopBodyScroll = void 0;
const stopBodyScroll = () => {
    document.body.style.overflow = 'hidden';
};
exports.stopBodyScroll = stopBodyScroll;
const startBodyScroll = () => {
    document.body.style.overflow = '';
};
exports.startBodyScroll = startBodyScroll;
