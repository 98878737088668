"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getProductFormElement = void 0;
const getDefaultSelectors_1 = require("../getDefaultSelectors");
const getProductFormElement = () => {
    var _a, _b, _c;
    const { defaultProductButtonSiblingSelector } = getDefaultSelectors_1.getDefaultSelectors((_a = window.blaze.uiSettings) === null || _a === void 0 ? void 0 : _a.storePlatform);
    const productButtonSiblingSelector = ((_b = window.blaze.uiSettings) === null || _b === void 0 ? void 0 : _b.buttonSiblingSelector) || defaultProductButtonSiblingSelector;
    const addToCartButton = document === null || document === void 0 ? void 0 : document.querySelector(productButtonSiblingSelector);
    if (((_c = window.blaze.uiSettings) === null || _c === void 0 ? void 0 : _c.storePlatform) === 'WOOCOMMERCE') {
        // find the closest form element
        return addToCartButton === null || addToCartButton === void 0 ? void 0 : addToCartButton.closest('form');
    }
    const closestForm = addToCartButton === null || addToCartButton === void 0 ? void 0 : addToCartButton.closest('form');
    const finalForm = (closestForm === null || closestForm === void 0 ? void 0 : closestForm.getAttribute('action')) === '/cart/add' ? closestForm : null;
    return finalForm || document.querySelector('[action="/cart/add"]');
};
exports.getProductFormElement = getProductFormElement;
