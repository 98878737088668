"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFinalFormData = exports.prepareProductData = void 0;
const fetchProductData_1 = require("../fetchProductData");
const getBlazeCheckoutButton_1 = require("../getBlazeCheckoutButton");
const getProductFormElement_1 = require("./getProductFormElement");
const prepareProductData = (options) => {
    if (options === null || options === void 0 ? void 0 : options.initial) {
        getBlazeCheckoutButton_1.setProductButtonInProgress(true);
        window.blaze.isInitialProductLoading = true;
    }
    return fetchData();
};
exports.prepareProductData = prepareProductData;
const fetchData = () => {
    return fetchProductData_1.fetchProductData()
        .then(data => {
        getBlazeCheckoutButton_1.setProductButtonInProgress(false);
        window.originalProductData = data.product;
        window.blaze.platformProductData = data;
        const productData = parseData(data.product);
        window.blaze.productData = productData;
        window.blaze.isInitialProductLoading = false;
        return productData;
    })
        .catch(err => {
        window.blaze.isInitialProductLoading = false;
        getBlazeCheckoutButton_1.setProductButtonInProgress(false);
        // eslint-disable-next-line no-console
        console.log('product loading error!', err);
    });
};
const getProductFormData = () => {
    const formElement = getProductFormElement_1.getProductFormElement();
    const formData = formElement && new FormData(formElement);
    const formDataObject = {};
    formData === null || formData === void 0 ? void 0 : formData.forEach((value, key) => {
        formDataObject[key] = value;
    });
    return formDataObject;
};
const parseFormData = () => {
    var _a;
    const formDataObject = getProductFormData();
    // handling variation_id for woocommerce
    if (((_a = window.blaze.uiSettings) === null || _a === void 0 ? void 0 : _a.storePlatform) === 'WOOCOMMERCE') {
        const variantId = formDataObject.variation_id ? parseInt(formDataObject.variation_id, 10) : 0;
        const quantity = parseInt(formDataObject.quantity, 10) || 1;
        return { variantId, quantity };
    }
    const variantId = parseInt(formDataObject.id, 10);
    const quantity = parseInt(formDataObject.quantity, 10) || 1;
    return { variantId, quantity };
};
const getQueryVariantId = () => {
    const params = new URL(document.location.href).searchParams;
    return parseInt(params.get('variant') || '', 10);
};
const isSingleWoocommerceProduct = (product) => {
    var _a;
    return ((_a = window.blaze.uiSettings) === null || _a === void 0 ? void 0 : _a.storePlatform) === 'WOOCOMMERCE' && product.variants.length === 1;
};
const getFinalFormData = (product) => {
    const queryVariantId = getQueryVariantId();
    const parsedFormData = parseFormData();
    const { variantId: formVariantId, quantity } = parsedFormData;
    let variantId = queryVariantId || formVariantId;
    const foundVariant = product.variants.find((variant) => variant.id === variantId) || product.variants[0];
    if (!variantId && variantId !== 0) {
        variantId = foundVariant.id;
    }
    // if single woocomerce product, set variantId to 0
    if (isSingleWoocommerceProduct(product)) {
        variantId = 0;
    }
    const variantTitle = product.variants.length > 1 ? foundVariant.option1 : '';
    const price = parseInt(foundVariant.price, 10) * 100;
    const isNoProductSelected = !isSingleWoocommerceProduct(product) && formVariantId === 0;
    window.blaze.isNoProductSelected = isNoProductSelected;
    return {
        variantId,
        quantity,
        variantTitle,
        price,
    };
};
exports.getFinalFormData = getFinalFormData;
const parseData = (product) => {
    var _a;
    const formData = exports.getFinalFormData(product);
    const { quantity, variantId, variantTitle, price } = formData;
    const productData = {
        items: [
            {
                variantId,
                productId: product.id,
                title: product.title,
                price,
                quantity,
                image: (_a = product.image) === null || _a === void 0 ? void 0 : _a.src,
                variantTitle,
            },
        ],
    };
    return productData;
};
