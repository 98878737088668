"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.observeProductForm = void 0;
const setupProductPage_1 = require("../setupProductPage");
const prepareProductData_1 = require("./prepareProductData");
const observeProductForm = (options) => {
    const blazeProductButton = document.querySelector('.blaze-product-button');
    const isProductPage = window.location.pathname.indexOf('/products/') !== -1;
    prepareProductData_1.prepareProductData();
    if (isProductPage && !blazeProductButton) {
        setupProductPage_1.setupProductPage(options);
    }
    const productObserver = new MutationObserver(checkProductVisible(options));
    productObserver.observe(document, { attributes: true, childList: true, characterData: false, subtree: true });
};
exports.observeProductForm = observeProductForm;
const checkProductVisible = (options) => (mutations, observer) => {
    mutations.forEach(mutation => {
        const formSelector = 'form[action="/cart/add"]';
        const addedNodes = [...mutation.addedNodes];
        const isFormPresent = document.contains(document.querySelector(formSelector));
        const isElementPresent = document.contains(document.querySelector(options.productButtonSiblingSelector));
        const isElementAdded = addedNodes.find(node => { var _a, _b; return (_b = (_a = node).matches) === null || _b === void 0 ? void 0 : _b.call(_a, options.productButtonSiblingSelector); });
        const isFormAdded = addedNodes.find(node => { var _a, _b; return (_b = (_a = node).matches) === null || _b === void 0 ? void 0 : _b.call(_a, formSelector); });
        const foundForm = isFormPresent || isFormAdded || isElementPresent || isElementAdded;
        if (foundForm) {
            const blazeButton = document.querySelector('.blaze-product-button');
            if (!blazeButton) {
                setupProductPage_1.setupProductPage(options);
            }
        }
    });
};
