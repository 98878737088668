"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trackGAStep = void 0;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const trackGAStep = (data) => {
    const { eventName, cartItems: items } = data;
    if (!window.ga) {
        return;
    }
    if (eventName === 'checkout.load') {
        initiateGATracking({ items });
    }
    if (eventName === 'checkout.shipping.load') {
        window.ga('ec:setAction', 'checkout', {
            step: 2,
        });
        window.ga('send', 'pageview', {
            page: '/checkout/shipping',
        });
        window.ga('send', 'event', {
            eventAction: 'Viewed Checkout - Shipping Page',
            eventCategory: 'All',
            eventLabel: false,
            eventValue: 0,
            nonInteraction: true,
        });
    }
    if (eventName === 'checkout.payment.load') {
        window.ga('ec:setAction', 'checkout', {
            step: 3,
        });
        window.ga('send', 'pageview', {
            page: '/checkout/payment',
        });
        window.ga('send', 'event', {
            eventAction: 'Viewed Checkout - Payment Page',
            eventCategory: 'All',
            eventLabel: false,
            eventValue: 0,
            nonInteraction: true,
        });
    }
    if (eventName === 'checkout.payment.success') {
        window.ga('ec:setAction', 'purchase', {
            step: 4,
        });
        window.ga('send', 'event', {
            eventAction: 'Viewed Checkout - Receipt Page',
            eventCategory: 'All',
            eventLabel: false,
            eventValue: 0,
            nonInteraction: true,
        });
        window.ga('send', 'pageview', {
            page: '/checkout/thank_you',
        });
        const { orderDisplayName, cartTotal, discountCode, shipping } = data;
        window.ga('ec:setAction', 'purchase', {
            id: orderDisplayName,
            affiliation: 'Blaze checkout',
            revenue: cartTotal,
            tax: '0.00',
            shipping,
            coupon: discountCode || '',
        });
        // this is  a custom event (blaze_purchase)
        window.ga('send', 'event', {
            eventCategory: 'EnhancedEcommerce',
            eventAction: 'Completed Order',
            eventLabel: 'blaze_purchase',
            nonInteraction: true,
        });
    }
};
exports.trackGAStep = trackGAStep;
const trackGAProducts = (products) => {
    products === null || products === void 0 ? void 0 : products.items.forEach(item => {
        window.ga('ec:addProduct', {
            id: item.productId,
            variant: item.variantTitle,
            name: item.title,
            quantity: item.quantity,
            price: item.price,
            currency: 'INR',
        });
    });
};
const initiateGATracking = (products) => {
    window.ga('require', 'ecommerce');
    window.ga('ec:setAction', 'checkout', {
        step: 1,
    });
    trackGAProducts(products);
    window.ga('send', 'pageview', {
        page: '/checkout/contact_information',
    });
    window.ga('send', 'event', {
        eventCategory: 'All',
        eventAction: 'Viewed Checkout - Contact Information Page',
        eventLabel: false,
        nonInteraction: true,
    });
    window.ga('send', 'event', {
        eventCategory: 'EnhancedEcommerce',
        eventAction: 'Started Order',
        eventLabel: 'blaze_checkout_initiated',
        nonInteraction: true,
    });
};
