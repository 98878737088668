"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchProductData = void 0;
const isProductPage = () => {
    return window.location.pathname.indexOf('/products/') !== -1;
};
const getProductDataPath = () => {
    return `${window.location.origin}/${window.location.pathname}.json`;
};
const getJSONLdUrl = () => {
    const allLdJsonNodes = [...document.querySelectorAll(`script[type="application/ld+json"]`)];
    let url;
    try {
        const productNode = allLdJsonNodes.find(node => {
            const json = JSON.parse(node.innerText);
            const type = json['@type'];
            return type === 'Product' || type === 'product';
        });
        url = productNode && JSON.parse(productNode.innerText).url;
    }
    catch (error) {
        // ignore error
    }
    return url && `${url}.json`;
};
const getJSONUrl = () => {
    const allJSONScriptNodes = [...document.querySelectorAll(`script[type="application/json"]`)];
    const productNode = allJSONScriptNodes.find(node => {
        let json = { product: false, handle: false };
        try {
            json = JSON.parse(node.innerText);
        }
        catch (_a) {
            // ignore
        }
        return !!((json && json.product) || json.handle);
    });
    const parsedNode = productNode && JSON.parse(productNode.innerText);
    const productData = (parsedNode === null || parsedNode === void 0 ? void 0 : parsedNode.product) || parsedNode;
    const url = (productData === null || productData === void 0 ? void 0 : productData.handle) && `${window.location.origin}/products/${productData.handle}`;
    return url && `${url}.json`;
};
const fetchProductData = () => {
    let url = isProductPage() ? getProductDataPath() : getJSONLdUrl();
    if (!url) {
        url = getJSONUrl();
    }
    if (window.wooSingleProductData) {
        return Promise.resolve(window.wooSingleProductData);
    }
    if (!url) {
        return Promise.reject(new Error('no product data'));
    }
    return fetch(url)
        .then(response => {
        return response.text();
    })
        .then(res => {
        const parsedData = JSON.parse(res);
        return parsedData;
    });
};
exports.fetchProductData = fetchProductData;
